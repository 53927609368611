import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Fancybox from "./Fancybox";

export default function Home() {
  const { t } = useTranslation();

  useEffect(() => {
    let frontEnd = document.createElement("script");
    frontEnd.src = "/assets/js/_1_accordion.js";
    frontEnd.id = "_1_accordion-js";
    document.body.appendChild(frontEnd);

    return () => {
      document.getElementById("_1_accordion-js").remove();
    };
  }, []);

  useEffect(() => {
    let frontEnd = document.createElement("script");
    frontEnd.src = "/assets/js/_2_anim-cards.js";
    frontEnd.id = "_2_anim-cards-js";
    document.body.appendChild(frontEnd);

    return () => {
      document.getElementById("_2_anim-cards-js").remove();
    };
  }, []);

  useEffect(() => {
    let frontEnd = document.createElement("script");
    frontEnd.src = "/assets/js/_1_scrolling-animations.js"; // 👈 make sure to use the correct path
    frontEnd.id = "_1_scrolling-animations-js";
    document.body.appendChild(frontEnd);

    return () => {
      document.getElementById("_1_scrolling-animations-js").remove();
    };
  }, []);

  return (
    <>
      <section
        className="hero min-height-100vh flex items-end color-white"
        style={{ backgroundImage: 'url("assets/img/hero-img-2.png")' }}
      >
        <div className="container max-width-adaptive-lg padding-y-xl">
          <div className="text-left">
            <div className="text-component margin-bottom-sm">
              <h1 id="intro" className="color-primary text-xxxxl">
                {t("home::Rental service")} <br />
                {t("home::of powerbanks")}
              </h1>
              <p className="text-md">{t("home::Charge on the go!")}</p>
            </div>
          </div>
        </div>
      </section>

      <section
        id="howto"
        className="position-relative z-index-1 flex flex-center padding-y-xl"
      >
        <div className="container max-width-adaptive-lg position-relative z-index-2">
          <div className="grid gap-lg items-center">
            <div className="col-7@md flex flex-center">
              <div className="device-group-1 padding-x-xxl">
                <div className="dev-phone-3d-wrapper">
                  <div
                    className="dev-phone dev-phone--top-light scroll-fx js-scroll-fx"
                    aria-label="Image description"
                    data-scroll-fx-1="rotateY, 40deg, 10deg, 0%, 100%"
                  >
                    <div className="dev-phone__body">
                      <div className="dev-phone__media">
                        <div className="dev-phone__notch" />
                        <img
                          src="assets/img/drop your design here copy.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="dev-phone__shadow" />
                  </div>
                </div>
                <div
                  className="dev-phone dev-phone--top-light"
                  aria-label="Image description"
                >
                  <div className="dev-phone__body">
                    <div className="dev-phone__media">
                      <div className="dev-phone__notch" />
                      <img
                        src="assets/img/Map Default energo mobile.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="dev-phone__shadow" />
                </div>
                <div className="dev-phone-3d-wrapper">
                  <div
                    className="dev-phone dev-phone--top-light scroll-fx js-scroll-fx"
                    aria-label="Image description"
                    data-scroll-fx-1="rotateY, -40deg, -10deg, 0%, 100%"
                  >
                    <div className="dev-phone__body">
                      <div className="dev-phone__media">
                        <div className="dev-phone__notch" />
                        <img
                          src="assets/img/drop your design here-3.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="dev-phone__shadow" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-5@md">
              <div
                className="margin-bottom-lg position-relative@md"
                style={{ left: "-85px" }}
              >
                <h1>{t("home::How it works?")}</h1>
              </div>
              <ol className="text-points text-points--counter grid gap-md margin-bottom-md">
                <li className="text-points__item">
                  <div>
                    <h3 className="text-points__title margin-bottom-sm">
                      {t("home::Install")}{" "}
                      <span className="font-light">
                        {t("home::the mobile app")}
                      </span>
                    </h3>
                    <div className="text-points__subtitle">
                      <div className="flex flex-wrap items-center gap-sm app">
                        <a
                          className="app-btn blu vert apple"
                          target="blank"
                          href="http://onelink.to/35pdxt"
                        >
                          <i className="fab fa-apple" />
                          <p>
                            {t("home::Download on the")} <br />
                            <span className="big-txt">App Store</span>
                          </p>
                        </a>
                        <a
                          className="app-btn blu vert"
                          target="blank"
                          href="http://onelink.to/35pdxt"
                        >
                          <i className="fab fa-google-play" />
                          <p>
                            {t("home::Get it on")} <br />
                            <span className="big-txt">Google Play</span>
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="text-points__item">
                  <div className="text-component">
                    <h3 className="text-points__title">
                      {t("home::Take")}{" "}
                      <span className="font-light">
                        {t("home::a powerbank")} <br />
                        {t("home::at the nearest terminal")}
                      </span>
                    </h3>
                    <p className="text-points__subtitle">
                      {t(
                        "home::Find the terminal in the application that is next to you. Scan the QR code or enter the number terminal."
                      )}
                    </p>
                  </div>
                </li>
                <li className="text-points__item">
                  <div className="text-component">
                    <h3 className="text-points__title">
                      {t("home::Charge")}{" "}
                      <span className="font-light">
                        {t("home::on the go")} <br />
                        {t("home::and hand over in a convenient place")}
                      </span>
                    </h3>
                    <p className="text-points__subtitle">
                      {t(
                        "home::Take the powerbank with you and return it anywhere from the app."
                      )}
                    </p>
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section id="partners" className="position-relative z-index-1">
        <div className="container max-width-lg position-relative z-index-2">
          <div className="margin-bottom-lg text-center">
            <h1 className="margin-bottom-sm color-inherit">
              {t("home::Where are we located?")}
            </h1>
            <p>{t("home::More than 1000 terminals throughout Moscow")}</p>
          </div>
          <div className="grid gap-lg margin-bottom-md">
            <div className="col-6 col-4@md">
              <div className="flex flex-column flex-center gap-sm">
                <figure className="flex-shrink-0">
                  <img
                    className="block width-xl width-xxxl@md"
                    src="assets/img/feature-v7-img-1.svg"
                    alt=""
                  />
                </figure>
                <div className="text-component text-center">
                  <h4 className="color-inherit font-light text-md">
                    {t("home::Transport hubs")}
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-6 col-4@md">
              <div className="flex flex-column flex-center gap-sm">
                <figure className="flex-shrink-0">
                  <img
                    className="block width-xl width-xxxl@md"
                    src="assets/img/feature-v7-img-2.svg"
                    alt=""
                  />
                </figure>
                <div className="text-component text-center">
                  <h4 className="color-inherit font-light text-md">
                    {t("home::Fitness clubs")}
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-6 col-4@md">
              <div className="flex flex-column flex-center gap-sm">
                <figure className="flex-shrink-0">
                  <img
                    className="block width-xl width-xxxl@md"
                    src="assets/img/feature-v7-img-3.svg"
                    alt=""
                  />
                </figure>
                <div className="text-component text-center">
                  <h4 className="color-inherit font-light text-md">
                    {t("home::Central markets")}
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-6 col-4@md">
              <div className="flex flex-column flex-center gap-sm">
                <figure className="flex-shrink-0">
                  <img
                    className="block width-xl width-xxxl@md"
                    src="assets/img/feature-v7-img-4.svg"
                    alt=""
                  />
                </figure>
                <div className="text-component text-center">
                  <h4 className="color-inherit font-light text-md">
                    {t("home::Cafes and restaurants")}
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-6 col-4@md">
              <div className="flex flex-column flex-center gap-sm">
                <figure className="flex-shrink-0">
                  <img
                    className="block width-xl width-xxxl@md"
                    src="assets/img/feature-v7-img-5.svg"
                    alt=""
                  />
                </figure>
                <div className="text-component text-center">
                  <h4 className="color-inherit font-light text-md">
                    {t("home::Shopping malls")}
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-6 col-4@md">
              <div className="flex flex-column flex-center gap-sm">
                <figure className="flex-shrink-0">
                  <img
                    className="block width-xl width-xxxl@md"
                    src="assets/img/feature-v7-img-6.svg"
                    alt=""
                  />
                </figure>
                <div className="text-component text-center">
                  <h4 className="color-inherit font-light text-md">
                    {t("home::All over the city")}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-center padding-y-md">
            <a
              target="blank"
              href="http://onelink.to/35pdxt"
              className="btn btn--primary btn--md"
            >
              {t("home::Find the nearest")}
            </a>
          </div>
          <div className="flex justify-between">
            <button
              className="reset anim-cards-control js-anim-cards-control js-tab-focus margin-left-auto"
              aria-controls="animated-cards"
              aria-label="pause animation"
              aria-pressed="false"
            >
              <i />
            </button>
          </div>
        </div>

        <Fancybox options={{ infinite: false }}>
          <div id="animated-cards" className="position-relative z-index-2">
            <div className="anim-cards js-anim-cards">
              <ul className="anim-cards__list">
                <li className="anim-cards__item">
                  <div className="card-v2 radius-md">
                    <a
                      data-fancybox="demo"
                      href="assets/img/anim-cards-img-1.png"
                    >
                      <img src="assets/img/anim-cards-img-1.png" alt="" />
                    </a>
                  </div>
                </li>
                <li className="anim-cards__item">
                  <div className="card-v2 radius-md">
                    <a
                      data-fancybox="demo"
                      href="assets/img/anim-cards-img-2.png"
                    >
                      <img src="assets/img/anim-cards-img-2.png" alt="" />
                    </a>
                  </div>
                </li>
                <li className="anim-cards__item">
                  <div className="card-v2 radius-md">
                    <a
                      data-fancybox="demo"
                      href="assets/img/anim-cards-img-3.png"
                    >
                      <img src="assets/img/anim-cards-img-3.png" alt="" />
                    </a>
                  </div>
                </li>
                <li className="anim-cards__item">
                  <div className="card-v2 radius-md">
                    <a
                      data-fancybox="demo"
                      href="assets/img/anim-cards-img-1.png"
                    >
                      <img src="assets/img/anim-cards-img-1.png" alt="" />
                    </a>
                  </div>
                </li>
                <li className="anim-cards__item">
                  <div className="card-v2 radius-md">
                    <a
                      data-fancybox="demo"
                      href="assets/img/anim-cards-img-2.png"
                    >
                      <img src="assets/img/anim-cards-img-2.png" alt="" />
                    </a>
                  </div>
                </li>
                <li className="anim-cards__item">
                  <div className="card-v2 radius-md">
                    <a
                      data-fancybox="demo"
                      href="assets/img/anim-cards-img-3.png"
                    >
                      <img src="assets/img/anim-cards-img-3.png" alt="" />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div className="anim-cards anim-cards--reverse js-anim-cards">
              <ul className="anim-cards__list">
                <li className="anim-cards__item">
                  <div className="card-v2 radius-md">
                    <a
                      data-fancybox="demo"
                      href="assets/img/anim-cards-img-1.png"
                    >
                      <img src="assets/img/anim-cards-img-1.png" alt="" />
                    </a>
                  </div>
                </li>
                <li className="anim-cards__item">
                  <div className="card-v2 radius-md">
                    <a
                      data-fancybox="demo"
                      href="assets/img/anim-cards-img-2.png"
                    >
                      <img src="assets/img/anim-cards-img-2.png" alt="" />
                    </a>
                  </div>
                </li>
                <li className="anim-cards__item">
                  <div className="card-v2 radius-md">
                    <a
                      data-fancybox="demo"
                      href="assets/img/anim-cards-img-3.png"
                    >
                      <img src="assets/img/anim-cards-img-3.png" alt="" />
                    </a>
                  </div>
                </li>
                <li className="anim-cards__item">
                  <div className="card-v2 radius-md">
                    <a
                      data-fancybox="demo"
                      href="assets/img/anim-cards-img-1.png"
                    >
                      <img src="assets/img/anim-cards-img-1.png" alt="" />
                    </a>
                  </div>
                </li>
                <li className="anim-cards__item">
                  <div className="card-v2 radius-md">
                    <a
                      data-fancybox="demo"
                      href="assets/img/anim-cards-img-2.png"
                    >
                      <img src="assets/img/anim-cards-img-2.png" alt="" />
                    </a>
                  </div>
                </li>
                <li className="anim-cards__item">
                  <div className="card-v2 radius-md">
                    <a
                      data-fancybox="demo"
                      href="assets/img/anim-cards-img-3.png"
                    >
                      <img src="assets/img/anim-cards-img-3.png" alt="" />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </Fancybox>
      </section>

      <section id="faq" className="position-relative z-index-1 padding-y-xl">
        <div className="container max-width-md position-relative z-index-2">
          <div className="margin-bottom-lg">
            <h1 className="text-center">{t("home::Frequent questions")}</h1>
          </div>
          <ul
            className="accordion js-accordion margin-bottom-xl"
            data-animation="on"
            data-multi-items="on"
          >
            <li className="accordion__item js-accordion__item">
              <button
                className="reset accordion__header padding-y-sm padding-x-md js-tab-focus"
                type="button"
              >
                <span className="text-md">
                  {t("home::How to take the battery?")}
                </span>
                <svg
                  className="icon accordion__icon-plus no-js:is-hidden"
                  viewBox="0 0 16 16"
                  aria-hidden="true"
                >
                  <g
                    className="icon__group"
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                  >
                    <line x1={1} y1={8} x2={15} y2={8} />
                    <line x1={1} y1={8} x2={15} y2={8} />
                  </g>
                </svg>
              </button>
              <div className="accordion__panel padding-top-xxs padding-x-md padding-bottom-md js-accordion__panel">
                <div className="text-component">
                  <ol>
                    <li>
                      <a target="blank" href="http://onelink.to/35pdxt">
                        {t("home::Install")}
                      </a>{" "}
                      {t("home::the mobile app")}
                    </li>
                    <li>{t("home::Log in to the app.")}</li>
                    <li>{t("home::Choose a convenient payment method.")}</li>
                    <li>{t("home::Find the nearest terminal on the map.")}</li>
                    <li>
                      {t(
                        "home::Scan the QR code on the terminal or enter its number manually."
                      )}
                    </li>
                    <li>
                      {t(
                        "home::After 10 seconds, remove the battery from the cell."
                      )}
                    </li>
                  </ol>
                </div>
              </div>
            </li>
            <li className="accordion__item js-accordion__item">
              <button
                className="reset accordion__header padding-y-sm padding-x-md js-tab-focus"
                type="button"
              >
                <span className="text-md">
                  {t("home::How much does the rent cost?")}
                </span>
                <svg
                  className="icon accordion__icon-plus no-js:is-hidden"
                  viewBox="0 0 16 16"
                  aria-hidden="true"
                >
                  <g
                    className="icon__group"
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                  >
                    <line x1={1} y1={8} x2={15} y2={8} />
                    <line x1={1} y1={8} x2={15} y2={8} />
                  </g>
                </svg>
              </button>
              <div className="accordion__panel padding-top-xxs padding-x-md padding-bottom-md js-accordion__panel">
                <div className="text-component">
                  <p>
                    {t("home::Basic rate:")} <br />
                    {t("home::50₽/first hour, then 100₽/per day")} <br />
                    {t(
                      "home::Please note that the cost may vary in different places and establishments."
                    )}{" "}
                    <br />
                    {t(
                      "home::The current rates are indicated on the terminals and in the application."
                    )}{" "}
                    <br />
                    {t(
                      "home::The money is debited from your card before the start of the rental and according to the time counter."
                    )}
                  </p>
                </div>
              </div>
            </li>
            <li className="accordion__item js-accordion__item">
              <button
                className="reset accordion__header padding-y-sm padding-x-md js-tab-focus"
                type="button"
              >
                <span className="text-md">
                  {t("home::How to return the battery?")}
                </span>
                <svg
                  className="icon accordion__icon-plus no-js:is-hidden"
                  viewBox="0 0 16 16"
                  aria-hidden="true"
                >
                  <g
                    className="icon__group"
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                  >
                    <line x1={1} y1={8} x2={15} y2={8} />
                    <line x1={1} y1={8} x2={15} y2={8} />
                  </g>
                </svg>
              </button>
              <div className="accordion__panel padding-top-xxs padding-x-md padding-bottom-md js-accordion__panel">
                <div className="text-component">
                  <ol>
                    <li>
                      {t(
                        "home::Find the nearest terminal on the map in the app."
                      )}
                    </li>
                    <li>
                      {t("home::Click the “return battery“ button in the app.")}
                    </li>
                    <li>{t("home::Insert the battery into a free cell.")}</li>
                  </ol>
                </div>
              </div>
            </li>
            <li className="accordion__item js-accordion__item">
              <button
                className="reset accordion__header padding-y-sm padding-x-md js-tab-focus"
                type="button"
              >
                <span className="text-md">
                  {t("home::Which phones can be charged?")}
                </span>
                <svg
                  className="icon accordion__icon-plus no-js:is-hidden"
                  viewBox="0 0 16 16"
                  aria-hidden="true"
                >
                  <g
                    className="icon__group"
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                  >
                    <line x1={1} y1={8} x2={15} y2={8} />
                    <line x1={1} y1={8} x2={15} y2={8} />
                  </g>
                </svg>
              </button>
              <div className="accordion__panel padding-top-xxs padding-x-md padding-bottom-md js-accordion__panel">
                <div className="text-component">
                  <p>{t("home::Each battery has three cables:")}</p>
                  <ol>
                    <li>Type-C</li>
                    <li>Lightning</li>
                    <li>MicroUSB</li>
                  </ol>
                </div>
              </div>
            </li>
            <li className="accordion__item js-accordion__item">
              <button
                className="reset accordion__header padding-y-sm padding-x-md js-tab-focus"
                type="button"
              >
                <span className="text-md">
                  {t("home::How many batteries")}{" "}
                  <span className="flex inline-flex@md">
                    {t("home::can I take?")}
                  </span>
                </span>
                <svg
                  className="icon accordion__icon-plus no-js:is-hidden"
                  viewBox="0 0 16 16"
                  aria-hidden="true"
                >
                  <g
                    className="icon__group"
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                  >
                    <line x1={1} y1={8} x2={15} y2={8} />
                    <line x1={1} y1={8} x2={15} y2={8} />
                  </g>
                </svg>
              </button>
              <div className="accordion__panel padding-top-xxs padding-x-md padding-bottom-md js-accordion__panel">
                <div className="text-component">
                  <p>
                    {t(
                      "home::You can take up to four powerbanks on one account."
                    )}
                  </p>
                </div>
              </div>
            </li>
            <li className="accordion__item js-accordion__item">
              <button
                className="reset accordion__header padding-y-sm padding-x-md js-tab-focus"
                type="button"
              >
                <span className="text-md">
                  {t("home::How many batteries")}{" "}
                  <span className="flex inline-flex@md">
                    {t("home::can I take?")}
                  </span>
                </span>
                <svg
                  className="icon accordion__icon-plus no-js:is-hidden"
                  viewBox="0 0 16 16"
                  aria-hidden="true"
                >
                  <g
                    className="icon__group"
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                  >
                    <line x1={1} y1={8} x2={15} y2={8} />
                    <line x1={1} y1={8} x2={15} y2={8} />
                  </g>
                </svg>
              </button>
              <div className="accordion__panel padding-top-xxs padding-x-md padding-bottom-md js-accordion__panel">
                <div className="text-component">
                  <p>
                    {t(
                      "home::You can take up to four powerbanks on one account."
                    )}
                  </p>
                </div>
              </div>
            </li>
            <li className="accordion__item js-accordion__item">
              <button
                className="reset accordion__header padding-y-sm padding-x-md js-tab-focus"
                type="button"
              >
                <span className="text-md">
                  {t("home::Cooperation with us")}
                </span>
                <svg
                  className="icon accordion__icon-plus no-js:is-hidden"
                  viewBox="0 0 16 16"
                  aria-hidden="true"
                >
                  <g
                    className="icon__group"
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                  >
                    <line x1={1} y1={8} x2={15} y2={8} />
                    <line x1={1} y1={8} x2={15} y2={8} />
                  </g>
                </svg>
              </button>
              <div className="accordion__panel padding-top-xxs padding-x-md padding-bottom-md js-accordion__panel">
                <div className="text-component">
                  <p>
                    {t("home::Contact the commercial department by number")}{" "}
                    <a
                      className="color-contrast-medium"
                      href="tel:+74951201876"
                    >
                      8 (495) 120-18-76
                    </a>{" "}
                    <br />
                    {t("home::On weekdays from 9:00 to 18:00")}
                  </p>
                </div>
              </div>
            </li>
            <li className="accordion__item js-accordion__item">
              <button
                className="reset accordion__header padding-y-sm padding-x-md js-tab-focus"
                type="button"
              >
                <span className="text-md">
                  {t(
                    "home::Why do we charge for a day if the power bank charges for only 2 hours?"
                  )}
                </span>
                <svg
                  className="icon accordion__icon-plus no-js:is-hidden"
                  viewBox="0 0 16 16"
                  aria-hidden="true"
                >
                  <g
                    className="icon__group"
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                  >
                    <line x1={1} y1={8} x2={15} y2={8} />
                    <line x1={1} y1={8} x2={15} y2={8} />
                  </g>
                </svg>
              </button>
              <div className="accordion__panel padding-top-xxs padding-x-md padding-bottom-md js-accordion__panel">
                <div className="text-component">
                  <p>
                    {t(
                      "home::The power bank charge is enough to fully charge the phone up to 100% in 2 hours (capacity 5000 mAh). The rates are calculated specifically so that you can safely hand over the powerbank at a convenient time and place for you."
                    )}
                  </p>
                </div>
              </div>
            </li>
          </ul>
          <div className="margin-bottom-lg">
            <h1 className="text-center">{t("home::What to do if")}</h1>
          </div>
          <ul
            className="accordion js-accordion padding-bottom-xl"
            data-animation="on"
            data-multi-items="on"
          >
            <li className="accordion__item js-accordion__item">
              <button
                className="reset accordion__header padding-y-sm padding-x-md js-tab-focus"
                type="button"
              >
                <span className="text-md">
                  {t("home::The terminal is not working")}
                </span>
                <svg
                  className="icon accordion__icon-plus no-js:is-hidden"
                  viewBox="0 0 16 16"
                  aria-hidden="true"
                >
                  <g
                    className="icon__group"
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                  >
                    <line x1={1} y1={8} x2={15} y2={8} />
                    <line x1={1} y1={8} x2={15} y2={8} />
                  </g>
                </svg>
              </button>
              <div className="accordion__panel padding-top-xxs padding-x-md padding-bottom-md js-accordion__panel">
                <div className="text-component">
                  <p>
                    {t("home::Contact technical support by phone")}{" "}
                    <span className="flex inline-flex@md">
                      <a
                        className="color-contrast-medium"
                        href="tel:+78006004264"
                      >
                        8 (800) 600-42-64
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </li>
            <li className="accordion__item js-accordion__item">
              <button
                className="reset accordion__header padding-y-sm padding-x-md js-tab-focus"
                type="button"
              >
                <span className="text-md">
                  {t("home::The QR code is not readable")}
                </span>
                <svg
                  className="icon accordion__icon-plus no-js:is-hidden"
                  viewBox="0 0 16 16"
                  aria-hidden="true"
                >
                  <g
                    className="icon__group"
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                  >
                    <line x1={1} y1={8} x2={15} y2={8} />
                    <line x1={1} y1={8} x2={15} y2={8} />
                  </g>
                </svg>
              </button>
              <div className="accordion__panel padding-top-xxs padding-x-md padding-bottom-md js-accordion__panel">
                <div className="text-component">
                  <p>
                    {t(
                      "home::If you are in the subway, then enter 6 digits under the QR code."
                    )}{" "}
                    <br />
                    {t(
                      "home::If in an institution, then contact technical support by phone"
                    )}{" "}
                    <span className="flex inline-flex@md">
                      <a
                        className="color-contrast-medium"
                        href="tel:+78006004264"
                      >
                        8 (800) 600-42-64
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </li>
            <li className="accordion__item js-accordion__item">
              <button
                className="reset accordion__header padding-y-sm padding-x-md js-tab-focus"
                type="button"
              >
                <span className="text-md">
                  {t("home::The terminal has run out of batteries")}
                </span>
                <svg
                  className="icon accordion__icon-plus no-js:is-hidden"
                  viewBox="0 0 16 16"
                  aria-hidden="true"
                >
                  <g
                    className="icon__group"
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                  >
                    <line x1={1} y1={8} x2={15} y2={8} />
                    <line x1={1} y1={8} x2={15} y2={8} />
                  </g>
                </svg>
              </button>
              <div className="accordion__panel padding-top-xxs padding-x-md padding-bottom-md js-accordion__panel">
                <div className="text-component">
                  <p>
                    {t(
                      "home::Find the nearest terminal in the application where there are free batteries."
                    )}
                  </p>
                </div>
              </div>
            </li>
            <li className="accordion__item js-accordion__item">
              <button
                className="reset accordion__header padding-y-sm padding-x-md js-tab-focus"
                type="button"
              >
                <span className="text-md">
                  {t(
                    "home::The lease started, but the terminal did not issue the battery"
                  )}
                </span>
                <svg
                  className="icon accordion__icon-plus no-js:is-hidden"
                  viewBox="0 0 16 16"
                  aria-hidden="true"
                >
                  <g
                    className="icon__group"
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                  >
                    <line x1={1} y1={8} x2={15} y2={8} />
                    <line x1={1} y1={8} x2={15} y2={8} />
                  </g>
                </svg>
              </button>
              <div className="accordion__panel padding-top-xxs padding-x-md padding-bottom-md js-accordion__panel">
                <div className="text-component">
                  <p>
                    {t(
                      "home::Check the cell again – you may not have seen the issued battery. If nothing has happened, contact technical support by phone"
                    )}{" "}
                    <a
                      className="color-contrast-medium"
                      href="tel:+78006004264"
                    >
                      8 (800) 600-42-64
                    </a>
                  </p>
                </div>
              </div>
            </li>
            <li className="accordion__item js-accordion__item">
              <button
                className="reset accordion__header padding-y-sm padding-x-md js-tab-focus"
                type="button"
              >
                <span className="text-md">
                  {t(
                    "home::The rented battery does not work (the indicator does not light, the cable is torn off)"
                  )}
                </span>
                <svg
                  className="icon accordion__icon-plus no-js:is-hidden"
                  viewBox="0 0 16 16"
                  aria-hidden="true"
                >
                  <g
                    className="icon__group"
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                  >
                    <line x1={1} y1={8} x2={15} y2={8} />
                    <line x1={1} y1={8} x2={15} y2={8} />
                  </g>
                </svg>
              </button>
              <div className="accordion__panel padding-top-xxs padding-x-md padding-bottom-md js-accordion__panel">
                <div className="text-component">
                  <p>
                    {t("home::Contact technical support by phone")}{" "}
                    <span className="flex inline-flex@md">
                      <a
                        className="color-contrast-medium"
                        href="tel:+78006004264"
                      >
                        8 (800) 600-42-64
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </li>
            <li className="accordion__item js-accordion__item">
              <button
                className="reset accordion__header padding-y-sm padding-x-md js-tab-focus"
                type="button"
              >
                <span className="text-md">
                  {t("home::Lost powerbank / damaged")}
                </span>
                <svg
                  className="icon accordion__icon-plus no-js:is-hidden"
                  viewBox="0 0 16 16"
                  aria-hidden="true"
                >
                  <g
                    className="icon__group"
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                  >
                    <line x1={1} y1={8} x2={15} y2={8} />
                    <line x1={1} y1={8} x2={15} y2={8} />
                  </g>
                </svg>
              </button>
              <div className="accordion__panel padding-top-xxs padding-x-md padding-bottom-md js-accordion__panel">
                <div className="text-component">
                  <p>
                    {t("home::Contact technical support by phone")}{" "}
                    <span className="flex inline-flex@md">
                      <a
                        className="color-contrast-medium"
                        href="tel:+78006004264"
                      >
                        8 (800) 600-42-64
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </li>
            <li className="accordion__item js-accordion__item">
              <button
                className="reset accordion__header padding-y-sm padding-x-md js-tab-focus"
                type="button"
              >
                <span className="text-md">
                  {t(
                    "home::There is no access to the phone number and you need to hand over the battery?"
                  )}
                </span>
                <svg
                  className="icon accordion__icon-plus no-js:is-hidden"
                  viewBox="0 0 16 16"
                  aria-hidden="true"
                >
                  <g
                    className="icon__group"
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                  >
                    <line x1={1} y1={8} x2={15} y2={8} />
                    <line x1={1} y1={8} x2={15} y2={8} />
                  </g>
                </svg>
              </button>
              <div className="accordion__panel padding-top-xxs padding-x-md padding-bottom-md js-accordion__panel">
                <div className="text-component">
                  <p>
                    {t(
                      "home::If you rent a powerbank to an institution, then just insert it into the terminal."
                    )}{" "}
                    <br />
                    {t(
                      "home::If in the subway, then contact technical support by phone"
                    )}{" "}
                    <span className="flex inline-flex@md">
                      <a
                        className="color-contrast-medium"
                        href="tel:+78006004264"
                      >
                        8 (800) 600-42-64
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </li>
          </ul>
          <div className="margin-bottom-lg text-center">
            <h1 className="margin-bottom-sm">
              {t("home::Any other questions?")}
            </h1>
            <p>{t("home::Write to us! We at EnerGO are all charged up!")}</p>
          </div>
          <ul className="socials__btns flex flex-center gap-sm flex-wrap">
            <li>
              <a target="blank" href="https://t.me/help_energo">
                <i className="fab fa-telegram-plane icon" />
                {/* <svg class="icon" viewbox="0 0 32 32"><title>Follow us on Twitter</title><g><path d="M32,6.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6C25.7,3.8,24,3,22.2,3 c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5C10.3,10.8,5.5,8.2,2.2,4.2c-0.6,1-0.9,2.1-0.9,3.3c0,2.3,1.2,4.3,2.9,5.5 c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1 c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1C2.9,27.9,6.4,29,10.1,29c12.1,0,18.7-10,18.7-18.7 c0-0.3,0-0.6,0-0.8C30,8.5,31.1,7.4,32,6.1z"></path></g></svg> */}
              </a>
            </li>
            <li>
              <a
                target="blank"
                href="https://wa.me/79265358000"
                className="padding-left-xxxs"
              >
                <i className="fab fa-whatsapp icon" />
                {/* <svg class="icon" viewbox="0 0 32 32"><title>Follow us on Facebook</title><path d="M32,16A16,16,0,1,0,13.5,31.806V20.625H9.438V16H13.5V12.475c0-4.01,2.389-6.225,6.043-6.225a24.644,24.644,0,0,1,3.582.312V10.5H21.107A2.312,2.312,0,0,0,18.5,13v3h4.438l-.71,4.625H18.5V31.806A16,16,0,0,0,32,16Z"></path></svg> */}
              </a>
            </li>
            <li>
              <a target="blank" href="mailto:support@energo.app">
                <i className="fas fa-envelope icon" />
                {/* <svg class="icon" viewbox="0 0 32 32"><title>Follow us on Facebook</title><path d="M32,16A16,16,0,1,0,13.5,31.806V20.625H9.438V16H13.5V12.475c0-4.01,2.389-6.225,6.043-6.225a24.644,24.644,0,0,1,3.582.312V10.5H21.107A2.312,2.312,0,0,0,18.5,13v3h4.438l-.71,4.625H18.5V31.806A16,16,0,0,0,32,16Z"></path></svg> */}
              </a>
            </li>
          </ul>
        </div>
      </section>

      <section className="position-relative z-index-1 flex color-black">
        <div className="container max-width-adaptive-lg position-relative z-index-2">
          <div className="grid gap-sm items-start">
            <div
              className="col-5@md position-relative z-index-2 padding-top-xl padding-top-0@md"
              style={{ alignSelf: "center" }}
            >
              <div className="text-component margin-bottom-md">
                {/* <p class="text-sm color-contrast-medium">How it works</p> */}
                <h1 className="color-inherit">
                  {t("home::Ready to be always")} <br />
                  {t("home::in touch?")}
                </h1>
                <p>
                  {t("home::Download the mobile app")} <br />
                  <span className="font-bold">EnerGO</span>{" "}
                  {t("home::from the App Store or Google Play")}
                </p>
              </div>
              <div className="margin-top-sm">
                <div className="flex flex-wrap items-center gap-sm">
                  <a
                    className="app-btn blu vert apple"
                    target="blank"
                    href="http://onelink.to/35pdxt"
                  >
                    <i className="fab fa-apple" />
                    <p>
                      {t("home::Download on the")} <br />
                      <span className="big-txt">App Store</span>
                    </p>
                  </a>
                  <a
                    className="app-btn blu vert"
                    target="blank"
                    href="http://onelink.to/35pdxt"
                  >
                    <i className="fab fa-google-play" />
                    <p>
                      {t("home::Get it on")} <br />
                      <span className="big-txt">Google Play</span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-7@md position-relative z-index-2 margin-bottom-sm flex flex-center">
              <div className="td-cards td-cards--v1">
                <div className="td-cards__inner">
                  <div
                    className="td-cards__card scroll-fx js-scroll-fx"
                    data-scroll-fx-1="rotateX, 60deg, 60deg, 0%, 100%"
                    data-scroll-fx-2="rotateZ, -60deg, -60deg, 0%, 100%"
                    data-scroll-fx-3="translateY, 10%, -55%, 0%, 100%"
                  >
                    <div
                      className="dev-phone dev-phone--top-light"
                      aria-label="Image description"
                    >
                      <div className="dev-phone__body">
                        <div className="dev-phone__media">
                          <div className="dev-phone__notch" />
                          <img
                            src="assets/img/drop your design here-1.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="dev-phone__shadow" />
                    </div>
                  </div>
                  <div
                    className="td-cards__card scroll-fx js-scroll-fx"
                    data-scroll-fx-1="rotateX, 60deg, 60deg, 0%, 100%"
                    data-scroll-fx-2="rotateZ, -60deg, -60deg, 0%, 100%"
                    data-scroll-fx-3="translateY, 20%, -25%, 0%, 100%"
                  >
                    <div
                      className="dev-phone dev-phone--top-light"
                      aria-label="Image description"
                    >
                      <div className="dev-phone__body">
                        <div className="dev-phone__media">
                          <div className="dev-phone__notch" />
                          <img
                            src="assets/img/Map Default energo mobile.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="dev-phone__shadow" />
                    </div>
                  </div>
                  <div
                    className="td-cards__card scroll-fx js-scroll-fx"
                    data-scroll-fx-1="rotateX, 60deg, 60deg, 0%, 100%"
                    data-scroll-fx-2="rotateZ, -60deg, -60deg, 0%, 100%"
                    data-scroll-fx-3="translateY, 30%, 0%, 0%, 100%"
                  >
                    <div
                      className="dev-phone dev-phone--top-light"
                      aria-label="Image description"
                    >
                      <div className="dev-phone__body">
                        <div className="dev-phone__media">
                          <div className="dev-phone__notch" />
                          <img
                            src="assets/img/drop your design here copy.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="dev-phone__shadow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="position-absolute inset-0 bg-primary z-index-1 origin-top-left scroll-fx js-scroll-fx"
          data-scroll-fx="skewY, -7deg, 0deg, 0%, 50%"
          aria-hidden="true"
        />
      </section>
    </>
  );
}
