import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Franchise() {
  const { t } = useTranslation();

  const [phone, setPhone] = useState("-");
  const [name, setName] = useState("-");
  const [email, setEmail] = useState("-");
  const [city, setCity] = useState("-");

  const [vs4, _vs4] = useState(5);
  const [vs8, _vs8] = useState(0);
  const [vs24, _vs24] = useState(0);
  const [vs48, _vs48] = useState(0);

  useEffect(() => {
    let frontEnd = document.createElement("script");
    frontEnd.src = "/assets/js/_2_anim-cards.js";
    frontEnd.id = "_2_anim-cards-js";
    document.body.appendChild(frontEnd);

    return () => {
      document.getElementById("_2_anim-cards-js").remove();
    };
  }, []);

  useEffect(() => {
    let frontEnd = document.createElement("script");
    frontEnd.src = "/assets/js/_1_scrolling-animations.js"; // 👈 make sure to use the correct path
    frontEnd.id = "_1_scrolling-animations-js";
    document.body.appendChild(frontEnd);

    return () => {
      document.getElementById("_1_scrolling-animations-js").remove();
    };
  }, []);

  useEffect(() => {
    let frontEnd = document.createElement("script");
    frontEnd.src = "/assets/js/_1_tabs.js";
    frontEnd.id = "_1_tabs-js";
    document.body.appendChild(frontEnd);

    return () => {
      document.getElementById("_1_tabs-js").remove();
    };
  }, []);

  useEffect(() => {
    let frontEnd = document.createElement("script");
    frontEnd.src = "//code-ya.jivosite.com/widget/VLpUeCEb41";
    frontEnd.id = "_jivosite-js";
    document.body.appendChild(frontEnd);

    return () => {
      document.getElementById("_jivosite-js").remove();
    };
  }, []);

  const s4 = parseInt(vs4) || 0;
  const s8 = parseInt(vs8) || 0;
  const s24 = parseInt(vs24) || 0;
  const s48 = parseInt(vs48) || 0;

  const inv = s4 * 20000 + s8 * 35000 + s24 * 130000 + s48 * 325000;
  const rev = s4 * 3168 + s8 * 5184 + s24 * 16560 + s48 * 38880;

  const tt = new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "RUB",
  });

  return (
    <>
      <section className="position-relative z-index-1 bg-primary color-black">
        <div className="container max-width-adaptive-lg">
          <div className="grid gap-lg items-center">
            <div className="col-6@md">
              <div className="text-component">
                <h1 className="text-xxxxl padding-top-md padding-top-0@md color-inherit">
                  {t("franchise::Charge")}
                  <br /> {t("franchise::with us!")}
                </h1>
                <p className="text-md">
                  {t("franchise::Become a part")}
                  <br /> {t("franchise::of the sharing services business")}
                </p>
              </div>
              <div className="margin-top-md">
                <div className="flex flex-wrap gap-sm items-center">
                  <div className="text-points__subtitle">
                    <div className="flex flex-wrap items-center gap-sm app">
                      <a
                        className="app-btn blu vert apple"
                        target="blank"
                        href="http://onelink.to/35pdxt"
                      >
                        <i className="fab fa-apple" />
                        <p>
                          {t("home::Download on the")} <br />
                          <span className="big-txt">App Store</span>
                        </p>
                      </a>
                      <a
                        className="app-btn blu vert"
                        target="blank"
                        href="http://onelink.to/35pdxt"
                      >
                        <i className="fab fa-google-play" />
                        <p>
                          {t("home::Get it on")} <br />
                          <span className="big-txt">Google Play</span>
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6@md">
              <figure className="feature-v12__bg-item">
                <img
                  className="block width-100%"
                  src="assets/img/feature-v12-img-1.png"
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </section>

      <section className="position-relative z-index-1 flex color-black padding-top-xl">
        <div className="container max-width-adaptive-lg position-relative z-index-2">
          <div className="grid gap-lg items-start">
            <div
              className="col-6@md position-relative z-index-2"
              style={{ alignSelf: "center" }}
            >
              <div className="text-component text-center margin-bottom-md">
                <h1 className="color-inherit text-xxxl">
                  {t("franchise::Why choose us?")}
                </h1>
              </div>
              <img src="assets/img/Group 1508.png" alt="" />
            </div>
            <div className="col-6@md">
              <div class="grid flex flex-column items-center gap-xs ">
                <a
                  class="card-v14 flex items-center justify-center padding-y-sm"
                  href="#0"
                >
                  <figure
                    class="card-v14__icon-wrapper margin-left-sm margin-right-sm margin-bottom-xs"
                    aria-hidden="true"
                  >
                    <svg
                      className="card-v14__icon icon"
                      width="33"
                      height="48"
                      viewBox="0 0 33 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.9247 20.0881L6.28906 48L32.9992 20.9967L15.9247 20.0881Z"
                        fill="var(--color-primary)"
                      />
                      <path
                        d="M17.0745 27.9119L26.7101 0L0 27.0034L17.0745 27.9119Z"
                        fill="var(--color-primary)"
                      />
                    </svg>
                  </figure>

                  <p class="color-contrast-medium line-height-md margin-y-xs min-width-66% text-md">
                    {t(
                      "franchise::Just manage. The key task is to install a charging station in a passageway and provide a second line of technical support."
                    )}
                  </p>
                </a>

                <a
                  class="card-v14 flex items-center justify-center padding-y-sm"
                  href="#0"
                >
                  <figure
                    class="card-v14__icon-wrapper margin-left-sm margin-right-sm margin-bottom-xs"
                    aria-hidden="true"
                  >
                    <svg
                      className="card-v14__icon icon"
                      width="33"
                      height="48"
                      viewBox="0 0 33 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.9247 20.0881L6.28906 48L32.9992 20.9967L15.9247 20.0881Z"
                        fill="var(--color-primary)"
                      />
                      <path
                        d="M17.0745 27.9119L26.7101 0L0 27.0034L17.0745 27.9119Z"
                        fill="var(--color-primary)"
                      />
                    </svg>
                  </figure>

                  <p class="color-contrast-medium line-height-md margin-y-xs min-width-66% text-md">
                    {t(
                      "franchise::The market is growing. Interest in the service is increasing every day."
                    )}
                  </p>
                </a>

                <a
                  class="card-v14 flex items-center justify-center padding-y-sm"
                  href="#0"
                >
                  <figure
                    class="card-v14__icon-wrapper margin-left-sm margin-right-sm margin-bottom-xs"
                    aria-hidden="true"
                  >
                    <svg
                      className="card-v14__icon icon"
                      width="33"
                      height="48"
                      viewBox="0 0 33 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.9247 20.0881L6.28906 48L32.9992 20.9967L15.9247 20.0881Z"
                        fill="var(--color-primary)"
                      />
                      <path
                        d="M17.0745 27.9119L26.7101 0L0 27.0034L17.0745 27.9119Z"
                        fill="var(--color-primary)"
                      />
                    </svg>
                  </figure>

                  <p class="color-contrast-medium line-height-md margin-y-xs min-width-66% text-md">
                    {t(
                      "franchise::No lump-sum payment. You only pay for the equipment."
                    )}
                  </p>
                </a>

                <a
                  class="card-v14 flex items-center justify-center padding-y-sm"
                  href="#0"
                >
                  <figure
                    class="card-v14__icon-wrapper margin-left-sm margin-right-sm margin-bottom-xs"
                    aria-hidden="true"
                  >
                    <svg
                      className="card-v14__icon icon"
                      width="33"
                      height="48"
                      viewBox="0 0 33 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.9247 20.0881L6.28906 48L32.9992 20.9967L15.9247 20.0881Z"
                        fill="var(--color-primary)"
                      />
                      <path
                        d="M17.0745 27.9119L26.7101 0L0 27.0034L17.0745 27.9119Z"
                        fill="var(--color-primary)"
                      />
                    </svg>
                  </figure>

                  <p class="color-contrast-medium line-height-md margin-y-xs min-width-66% text-md">
                    {t(
                      "franchise::The opportunity to purchase a Master franchise. Manage a city, region or country!"
                    )}
                  </p>
                </a>

                <a
                  class="card-v14 flex items-center justify-center padding-y-sm"
                  href="#0"
                >
                  <figure
                    class="card-v14__icon-wrapper margin-left-sm margin-right-sm margin-bottom-xs"
                    aria-hidden="true"
                  >
                    <svg
                      className="card-v14__icon icon"
                      width="33"
                      height="48"
                      viewBox="0 0 33 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.9247 20.0881L6.28906 48L32.9992 20.9967L15.9247 20.0881Z"
                        fill="var(--color-primary)"
                      />
                      <path
                        d="M17.0745 27.9119L26.7101 0L0 27.0034L17.0745 27.9119Z"
                        fill="var(--color-primary)"
                      />
                    </svg>
                  </figure>

                  <p class="color-contrast-medium line-height-md margin-y-xs min-width-66% text-md">
                    {t(
                      "franchise::Quick profit - revenue from the first days of installation."
                    )}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          className="position-absolute inset-0 bottom-0 bottom-xxxl@md bg-primary z-index-1 origin-top-left scroll-fx js-scroll-fx"
          // data-scroll-fx="skewY, -7deg, 0deg, 0%, 50%"
          aria-hidden="true"
        />
      </section>

      <section id="howto" className="position-relative z-index-1 padding-y-xl">
        <div className="container max-width-adaptive-lg">
          <div className="grid gap-lg items-center">
            <div className="col-1@md d-none d-flex@md"></div>

            <div className="col-4@md">
              <div
                className="margin-bottom-lg position-relative@md"
                style={{ left: "-85px" }}
              >
                <h1>{t("home::How it works?")}</h1>
              </div>
              <ol className="text-points text-points--counter grid gap-md margin-bottom-md">
                <li className="text-points__item">
                  <div>
                    <h3 className="text-points__title margin-bottom-sm">
                      {t("home::Install")}{" "}
                      <span className="font-light">
                        {t("home::the mobile app")}
                      </span>
                    </h3>
                    <div className="text-points__subtitle">
                      <div className="flex flex-wrap items-center gap-sm app">
                        <a
                          className="app-btn blu vert apple"
                          target="blank"
                          href="http://onelink.to/35pdxt"
                        >
                          <i className="fab fa-apple" />
                          <p>
                            {t("home::Download on the")} <br />
                            <span className="big-txt">App Store</span>
                          </p>
                        </a>
                        <a
                          className="app-btn blu vert"
                          target="blank"
                          href="http://onelink.to/35pdxt"
                        >
                          <i className="fab fa-google-play" />
                          <p>
                            {t("home::Get it on")} <br />
                            <span className="big-txt">Google Play</span>
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="text-points__item">
                  <div className="text-component">
                    <h3 className="text-points__title">
                      {t("home::Take")}{" "}
                      <span className="font-light">
                        {t("home::a powerbank")} <br />
                        {t("home::at the nearest terminal")}
                      </span>
                    </h3>
                    <p className="text-points__subtitle">
                      {t(
                        "home::Find the terminal in the application that is next to you. Scan the QR code or enter the number terminal."
                      )}
                    </p>
                  </div>
                </li>
                <li className="text-points__item">
                  <div className="text-component">
                    <h3 className="text-points__title">
                      {t("home::Charge")}{" "}
                      <span className="font-light">
                        {t("home::on the go")} <br />
                        {t("home::and hand over in a convenient place")}
                      </span>
                    </h3>
                    <p className="text-points__subtitle">
                      {t(
                        "home::Take the powerbank with you and return it anywhere from the app."
                      )}
                    </p>
                  </div>
                </li>
              </ol>
            </div>

            <div className="col-7@md flex flex-end">
              <div className="device-group-1">
                <div className="dev-phone-3d-wrapper">
                  <div
                    className="dev-phone dev-phone--top-light scroll-fx js-scroll-fx"
                    aria-label="Image description"
                    data-scroll-fx-1="rotateY, 40deg, 10deg, 0%, 100%"
                  >
                    <div className="dev-phone__body">
                      <div className="dev-phone__media">
                        <div className="dev-phone__notch" />
                        <img
                          src="assets/img/drop your design here copy.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="dev-phone__shadow" />
                  </div>
                </div>
                <div
                  className="dev-phone dev-phone--top-light"
                  aria-label="Image description"
                >
                  <div className="dev-phone__body">
                    <div className="dev-phone__media">
                      <div className="dev-phone__notch" />
                      <img
                        src="assets/img/Map Default energo mobile.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="dev-phone__shadow" />
                </div>
                <div className="dev-phone-3d-wrapper">
                  <div
                    className="dev-phone dev-phone--top-light scroll-fx js-scroll-fx"
                    aria-label="Image description"
                    data-scroll-fx-1="rotateY, -40deg, -10deg, 0%, 100%"
                  >
                    <div className="dev-phone__body">
                      <div className="dev-phone__media">
                        <div className="dev-phone__notch" />
                        <img
                          src="assets/img/drop your design here-3.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="dev-phone__shadow" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" bg-contrast position-relative z-index-1 padding-bottom-xl">
        <div className="container max-width-adaptive-lg position-relative z-index-2">
          <div className="margin-bottom-lg text-center">
            <h1 className="margin-bottom-sm margin-top-lg margin-top-0@md color-inherit">
              {t("franchise::What do we offer?")}
            </h1>
          </div>
          <div className="grid gap-lg items-center">
            <div className="col-7@md">
              <div className="text-component">
                <ol className="list-v2 list-v2--icons">
                  <li>
                    <div className="margin-bottom-xs">
                      <h3 className="list-v2__title">
                        <figure className="list-v2__bullet" aria-hidden="true">
                          <svg
                            className="list-v2__icon icon"
                            width="29"
                            height="76"
                            viewBox="0 0 29 76"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M29 38.2999L18.4063 38.0808L24.4775 0L0 37.7001L10.5937 37.9192L4.52253 76L29 38.2999Z"
                              fill="var(--color-contrast-higher)"
                            />
                          </svg>
                        </figure>
                        {t("franchise::Stations with chargers")}
                      </h3>
                    </div>
                    <div className="list-v2__content">
                      <div className="text-component">
                        <p className="color-contrast-higher">
                          {t("franchise::4, 8, 24, 48 slots")}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="margin-bottom-xs">
                      <h3 className="list-v2__title">
                        <figure className="list-v2__bullet" aria-hidden="true">
                          <svg
                            className="list-v2__icon icon"
                            width="29"
                            height="76"
                            viewBox="0 0 29 76"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M29 38.2999L18.4063 38.0808L24.4775 0L0 37.7001L10.5937 37.9192L4.52253 76L29 38.2999Z"
                              fill="var(--color-contrast-higher)"
                            />
                          </svg>
                        </figure>
                        {t("franchise::Technical support")}
                      </h3>
                    </div>
                    <div className="list-v2__content">
                      <div className="text-component">
                        <p className="color-contrast-higher">
                          {t(
                            "franchise::We take over most of the work, you only need to arrange the stations"
                          )}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="margin-bottom-xs">
                      <h3 className="list-v2__title">
                        <figure className="list-v2__bullet" aria-hidden="true">
                          <svg
                            className="list-v2__icon icon"
                            width="29"
                            height="76"
                            viewBox="0 0 29 76"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M29 38.2999L18.4063 38.0808L24.4775 0L0 37.7001L10.5937 37.9192L4.52253 76L29 38.2999Z"
                              fill="var(--color-contrast-higher)"
                            />
                          </svg>
                        </figure>
                        {t("franchise::Software")}
                      </h3>
                    </div>
                  </li>
                  <li>
                    <div className="margin-bottom-xs">
                      <h3 className="list-v2__title">
                        <figure className="list-v2__bullet" aria-hidden="true">
                          <svg
                            className="list-v2__icon icon"
                            width="29"
                            height="76"
                            viewBox="0 0 29 76"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M29 38.2999L18.4063 38.0808L24.4775 0L0 37.7001L10.5937 37.9192L4.52253 76L29 38.2999Z"
                              fill="var(--color-contrast-higher)"
                            />
                          </svg>
                        </figure>
                        {t("franchise::Partner's personal account")}
                      </h3>
                    </div>
                    <div className="list-v2__content">
                      <div className="text-component">
                        <p className="color-contrast-higher">
                          {t(
                            "franchise::You will be able to see the service usage statistics"
                          )}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="margin-bottom-xs">
                      <h3 className="list-v2__title">
                        <figure className="list-v2__bullet" aria-hidden="true">
                          <svg
                            className="list-v2__icon icon"
                            width="29"
                            height="76"
                            viewBox="0 0 29 76"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M29 38.2999L18.4063 38.0808L24.4775 0L0 37.7001L10.5937 37.9192L4.52253 76L29 38.2999Z"
                              fill="var(--color-contrast-higher)"
                            />
                          </svg>
                        </figure>
                        {t("franchise::Franchisee's Guide")}
                      </h3>
                    </div>
                    <div className="list-v2__content">
                      <div className="text-component">
                        <p className="color-contrast-higher">
                          {t(
                            "franchise::Instructions for setting up equipment, recommendations for placement locations"
                          )}
                        </p>
                      </div>
                    </div>
                  </li>
                </ol>
              </div>
            </div>

            <div className="col-5@md flex flex-center">
              <div
                className="dev-phone dev-phone--top-light max-width-xxxxs"
                aria-label=""
              >
                <div className="dev-phone__body">
                  <div className="dev-phone__media">
                    <div className="dev-phone__notch"></div>
                    <img src="assets/img/image 17.png" alt="" />
                  </div>
                </div>
                <div className="dev-phone__shadow"></div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="position-absolute inset-0 top-50% bg-primary z-index-1 origin-top-left"
          aria-hidden="true"
        >
          <div class="custom-section-divider bg">
            <svg viewBox="0 0 1920 60" aria-hidden="true">
              <path
                fill="var(--color-primary)"
                d="M-153.5,85.5a4002.033,4002.033,0,0,1,658-71c262.854-6.5,431.675,15.372,600,27,257.356,17.779,624.828,19.31,1089-58v102Z"
              />
            </svg>
          </div>
        </div>
      </section>

      <section className="position-relative z-index-1 margin-y-lg">
        <div className="container max-width-adaptive-lg">
          <div className="margin-bottom-lg text-center">
            <h1 className="margin-bottom-sm color-inherit">
              {t("franchise::Terminal formats")}
            </h1>
            <p>{t("franchise::1000+ terminals throughout Moscow")}</p>
          </div>

          <div className="tabs js-tabs">
            <ul
              className="flex flex-center flex-wrap gap-md js-tabs__controls"
              aria-label="Tabs Interface"
            >
              <li className="order-2">
                <a
                  href="#tab1Panel1"
                  className="tabs__control font-bold text-md btn btn--primary btn--md"
                >
                  {t("franchise::8 slots")}
                </a>
              </li>
              <li className="order-1">
                <a
                  href="#tab1Panel2"
                  className="tabs__control font-bold text-md btn btn--primary btn--md"
                >
                  {t("franchise::4 slots")}
                </a>
              </li>
              <li className="order-3">
                <a
                  href="#tab1Panel3"
                  className="tabs__control font-bold text-md btn btn--primary btn--md"
                >
                  {t("franchise::24 slots")}
                </a>
              </li>
              <li className="order-4">
                <a
                  href="#tab1Panel4"
                  className="tabs__control font-bold text-md btn btn--primary btn--md"
                >
                  {t("franchise::48 slots")}
                </a>
              </li>
            </ul>
            <div className="js-tabs__panels">
              <section
                id="tab1Panel2"
                className="padding-top-md js-tabs__panel"
              >
                <div className="bg-light inner-glow shadow-sm radius-lg">
                  <div className="grid">
                    <div className="flex items-center col-6@md order-2@md">
                      <div className="padding-md padding-x-lg@lg padding-y-xxl@lg">
                        <div className="text-sm color-contrast-medium margin-bottom-xxs">
                          {t("franchise::Size")} 24.8x18.9x21.5
                        </div>
                        <div className="text-component">
                          <h1>
                            {t("franchise::Terminal for")}{" "}
                            {t("franchise::8 slots")}
                          </h1>
                          <p>{t("franchise::Compact, ideal for:")}</p>

                          <ul>
                            <li>{t("franchise::cafes, bars, restaurants")}</li>
                            <li>{t("franchise::beauty salons")}</li>
                            <li>{t("franchise::other establishments")}</li>
                          </ul>

                          <p>
                            {t(
                              "franchise::The station is completely autonomous, only the presence of an outlet is required."
                            )}
                          </p>
                        </div>
                        <div className="margin-top-sm">
                          <div className="flex flex-wrap gap-sm items-center">
                            <Link
                              to="/franchise#form"
                              className="btn btn--primary"
                            >
                              {t("franchise::Send a request")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <figure className="col-6@md flex justify-center items-center">
                      <img
                        className="block object-cover order-1@md"
                        src="assets/img/boxed-feature-img-1.png"
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </section>

              <section
                id="tab1Panel1"
                className="padding-top-md js-tabs__panel"
              >
                <div className="bg-light inner-glow shadow-sm radius-lg">
                  <div className="grid">
                    <div className="flex items-center col-6@md order-2@md">
                      <div className="padding-md padding-x-lg@lg padding-y-xxl@lg">
                        <div className="text-sm color-contrast-medium margin-bottom-xxs">
                          {t("franchise::Size")} 19.5x10x12.8
                        </div>
                        <div className="text-component">
                          <h1>
                            {t("franchise::Terminal for")}{" "}
                            {t("franchise::4 slots")}
                          </h1>
                          <p>{t("franchise::Compact, ideal for:")}</p>

                          <ul>
                            <li>{t("franchise::coffee shops")}</li>
                            <li>{t("franchise::beauty salons")}</li>
                            <li>{t("franchise::barbershops")}</li>
                          </ul>

                          <p>
                            {t(
                              "franchise::The station is completely autonomous, only the presence of an outlet is required."
                            )}
                          </p>
                        </div>
                        <div className="margin-top-sm">
                          <div className="flex flex-wrap gap-sm items-center">
                            <Link
                              to="/franchise#form"
                              className="btn btn--primary"
                            >
                              {t("franchise::Send a request")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <figure className="col-6@md flex justify-center items-center">
                      <img
                        className="block object-cover order-1@md"
                        src="assets/img/boxed-feature-img-1.png"
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </section>

              <section
                id="tab1Panel3"
                className="padding-top-md js-tabs__panel"
              >
                <div className="bg-light inner-glow shadow-sm radius-lg">
                  <div className="grid">
                    <div className="flex items-center col-6@md order-2@md">
                      <div className="padding-md padding-x-lg@lg padding-y-xxl@lg">
                        <div className="text-sm color-contrast-medium margin-bottom-xxs">
                          {t("franchise::Size")} 188x42.5x42.5
                        </div>
                        <div className="text-component">
                          <h1>
                            {t("franchise::Terminal for")}{" "}
                            {t("franchise::24 slots")}
                          </h1>
                          <p>{t("franchise::Compact, ideal for:")}</p>

                          <ul>
                            <li>{t("franchise::markets")}</li>
                            <li>{t("franchise::universities")}</li>
                            <li>{t("franchise::polyclinics")}</li>
                          </ul>

                          <p>
                            {t(
                              "franchise::The station is completely autonomous, only the presence of an outlet is required."
                            )}
                          </p>
                        </div>
                        <div className="margin-top-sm">
                          <div className="flex flex-wrap gap-sm items-center">
                            <Link
                              to="/franchise#form"
                              className="btn btn--primary"
                            >
                              {t("franchise::Send a request")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <figure className="col-6@md flex justify-center items-center">
                      <img
                        className="block object-cover order-1@md"
                        src="assets/img/boxed-feature-img-1.png"
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </section>
              <section
                id="tab1Panel4"
                className="padding-top-md js-tabs__panel"
              >
                <div className="bg-light inner-glow shadow-sm radius-lg">
                  <div className="grid">
                    <div className="flex items-center col-6@md order-2@md">
                      <div className="padding-md padding-x-lg@lg padding-y-xxl@lg">
                        <div className="text-sm color-contrast-medium margin-bottom-xxs">
                          {t("franchise::Size")} 200x65x22
                        </div>
                        <div className="text-component">
                          <h1>
                            {t("franchise::Terminal for")}{" "}
                            {t("franchise::48 slots")}
                          </h1>
                          <p>{t("franchise::Compact, ideal for:")}</p>

                          <ul>
                            <li>{t("franchise::shopping centers")}</li>
                            <li>{t("franchise::airports")}</li>
                            <li>{t("franchise::train stations")}</li>
                          </ul>

                          <p>
                            {t(
                              "franchise::The station is completely autonomous, only the presence of an outlet is required."
                            )}
                          </p>
                        </div>
                        <div className="margin-top-sm">
                          <div className="flex flex-wrap gap-sm items-center">
                            <Link
                              to="/franchise#form"
                              className="btn btn--primary"
                            >
                              {t("franchise::Send a request")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <figure className="col-6@md flex justify-center items-center">
                      <img
                        className="block object-cover order-1@md"
                        src="assets/img/boxed-feature-img-1.png"
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      <section className="position-relative z-index-1 padding-y-xl bg-contrast-lower">
        <div className="container max-width-lg position-relative z-index-2">
          <div className="margin-bottom-lg text-center">
            <h1 className="margin-bottom-sm color-inherit">
              {t("franchise::Left a request")}
            </h1>
            <p className="text-md">{t("franchise::What's next?")}</p>
          </div>
          <div className="grid gap-lg margin-bottom-md">
            <div className="col-6 col-4@md">
              <div className="flex flex-column flex-center gap-sm">
                <div className="text-component text-center">
                  <h4 className="color-inherit text-xl">
                    <span className="text-lg">24</span> {t("franchise::hours")}
                  </h4>
                  <p>
                    {t("franchise::the time for which")} <br />{" "}
                    {t("franchise::we will contact you")}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-4@md">
              <div className="flex flex-column flex-center gap-sm">
                <div className="text-component text-center">
                  <h4 className="color-inherit text-xl">
                    <span className="text-lg">1</span> {t("franchise::call")}
                  </h4>
                  <p>
                    {t(
                      "franchise::we will answer all your questions and give you a personal offer"
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-4@md">
              <div className="flex flex-column flex-center gap-sm">
                <div className="text-component text-center">
                  <h4 className="color-inherit text-xl">
                    <span className="text-lg">3</span> {t("franchise::days")}
                  </h4>
                  <p>
                    {t("franchise::the deadline for the conclusion")} <br />{" "}
                    {t("franchise::of the contract")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="form"
        className="position-relative z-index-1 padding-y-xl bg-primary color-black"
      >
        <div className="container max-width-adaptive-lg position-relative z-index-2">
          <div className="margin-bottom-lg text-center">
            <h1 className="margin-bottom-sm color-inherit">
              {t("franchise::Let's charge people together!")}
            </h1>
            <p className="text-md">
              {t("franchise::Send a request and get an offer for your city")}
            </p>
          </div>
          <div className="grid gap-sm">
            <div className="col-6@md">
              <form
                className="form-template-v3 shadow-sm radius-lg padding-md bg color-contrast-higher "
                style={{ height: "100%" }}
              >
                <div className="flex flex-column margin-bottom-md">
                  <div className="col-12@md margin-bottom-sm">
                    <div className="form-label text-md">
                      {t("franchise::Enter the quantity")}
                    </div>
                  </div>
                  <div className="col-12@md">
                    <ul className="grid gap-xs flex flex-nowrap">
                      <li className="col-4@md">
                        <ul className="grid gap-xs flex flex-column">
                          <li className="grid gap-xs flex flex-nowrap items-center">
                            <div className="col-5">
                              <input
                                className="form-control width-100%"
                                type="text"
                                name="4slots"
                                id="4slots"
                                value={vs4}
                                onChange={(e) => _vs4(e.target.value)}
                                required
                              />
                            </div>
                            <div className="col-7">
                              <label htmlFor="4slots">
                                {t("franchise::4 slots")}
                              </label>
                            </div>
                          </li>
                          <li className="grid gap-xs flex flex-nowrap items-center">
                            <div className="col-5">
                              <input
                                className="form-control width-100%"
                                type="text"
                                name="8slots"
                                id="8slots"
                                value={vs8}
                                onChange={(e) => _vs8(e.target.value)}
                                required
                              />
                            </div>
                            <div className="col-7">
                              <label htmlFor="8slots">
                                {t("franchise::8 slots")}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className="col-4@md">
                        <ul className="grid gap-xs flex flex-column">
                          <li className="grid gap-xs flex flex-nowrap items-center">
                            <div className="col-5">
                              <input
                                className="form-control width-100%"
                                type="text"
                                name="24slots"
                                id="24slots"
                                value={vs24}
                                onChange={(e) => _vs24(e.target.value)}
                                required
                              />
                            </div>
                            <div className="col-7">
                              <label htmlFor="24slots">
                                {t("franchise::24 slots")}
                              </label>
                            </div>
                          </li>
                          <li className="grid gap-xs flex flex-nowrap items-center">
                            <div className="col-5">
                              <input
                                className="form-control width-100%"
                                type="text"
                                name="48slots"
                                id="48slots"
                                value={vs48}
                                onChange={(e) => _vs48(e.target.value)}
                                required
                              />
                            </div>
                            <div className="col-7">
                              <label htmlFor="48slots">
                                {t("franchise::48 slots")}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="grid gap-xxs flex flex-column margin-bottom-sm">
                  <div className="col-6@md">
                    <label
                      className="form-label text-md margin-bottom-xxxs"
                      htmlFor="investment"
                    >
                      {t("franchise::Your investment")}
                    </label>
                  </div>
                  <div className="col-6@md">
                    <input
                      disabled
                      value={tt.format(inv).replace(",00", "")}
                      className="form-control width-100%"
                      type="text"
                      name="investment"
                      id="investment"
                      required
                    />
                  </div>
                </div>

                <div className="grid gap-xxs flex flex-column margin-bottom-md">
                  <div className="col-6@md">
                    <label
                      className="form-label text-md margin-bottom-xxxs"
                      htmlFor="revenue"
                    >
                      {t("franchise::Revenue in 12 months")}
                    </label>
                  </div>
                  <div className="col-6@md">
                    <input
                      disabled
                      value={tt.format(rev).replace(",00", "")}
                      className="form-control width-100%"
                      type="text"
                      name="revenue"
                      id="revenue"
                      required
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="col-6@md">
              <form className="contact-form bg color-contrast-higher padding-md radius-lg shadow-sm ">
                <div className="margin-bottom-sm">
                  <label
                    className="form-label margin-bottom-xxxs text-md "
                    htmlFor="input-phone"
                  >
                    {t("franchise::Phone number")}
                  </label>
                  <input
                    className="form-control width-100%"
                    type="tel"
                    name="input-phone"
                    id="input-phone"
                    placeholder=""
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="margin-bottom-sm">
                  <label
                    className="form-label margin-bottom-xxxs text-md"
                    htmlFor="input-name"
                  >
                    {t("franchise::First Last Name")}
                  </label>
                  <input
                    className="form-control width-100%"
                    type="name"
                    name="input-name"
                    id="input-name"
                    placeholder=""
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="margin-bottom-sm">
                  <label
                    className="form-label margin-bottom-xxxs text-md"
                    htmlFor="input-email"
                  >
                    {t("franchise::Email")}
                  </label>
                  <input
                    className="form-control width-100%"
                    type="email"
                    name="input-email"
                    id="input-email"
                    placeholder=""
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="margin-bottom-lg">
                  <label
                    className="form-label margin-bottom-xxxs text-md"
                    htmlFor="input-city"
                  >
                    {t("franchise::City")}
                  </label>
                  <input
                    className="form-control width-100%"
                    type="text"
                    name="input-city"
                    id="input-city"
                    placeholder=""
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
                <div className="margin-bottom-md text-center">
                  <a
                    target="blank"
                    className="btn btn--primary btn--md"
                    href={`mailto:fr@energo.app?subject=${"Франшиза EnerGo"}&body=Номер телефона:%0D%0A${phone}%0D%0A%0D%0AИмя Фамилия:%0D%0A${name}%0D%0A%0D%0AПочта:%0D%0A${email}%0D%0A%0D%0AГород:%0D%0A${city}%0D%0A%0D%0A`}
                  >
                    {t("franchise::Make order")}
                  </a>
                </div>
                <div className="text-center">
                  <p className="text-sm">
                    {t("franchise::By clicking the button you agree to our")}{" "}
                    <br />{" "}
                    <a
                      target="blank"
                      className="color-contrast-higher"
                      href="ПОЛЬЗОВАТЕЛЬСКОЕ_СОГЛАШЕНИЕ_19_09_2021.pdf"
                    >
                      {t("franchise::privacy policy")}
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className="position-relative z-index-1 flex color-black padding-y-md padding-y-xl@md">
        <div className="container max-width-adaptive-lg position-relative z-index-2">
          <div className="grid gap-sm items-start">
            <div
              className="col-6@md position-relative z-index-2"
              style={{ alignSelf: "center" }}
            >
              <div className="text-component margin-bottom-md">
                {/* <p class="text-sm color-contrast-medium">How it works</p> */}
                <h1 className="color-inherit padding-bottom-sm text-xxxl">
                  {t("franchise::Charge on the go!")}
                </h1>
                <p>
                  {t("home::Download the mobile app")} <br />
                  <span className="font-bold">EnerGO</span>{" "}
                  {t("home::from the App Store or Google Play")}
                </p>
              </div>
              <div className="margin-top-sm margin-bottom-md">
                <div className="flex flex-wrap items-center gap-sm">
                  <a
                    className="app-btn blu vert apple"
                    target="blank"
                    href="http://onelink.to/35pdxt"
                  >
                    <i className="fab fa-apple" />
                    <p>
                      {t("home::Download on the")} <br />
                      <span className="big-txt">App Store</span>
                    </p>
                  </a>
                  <a
                    className="app-btn blu vert"
                    target="blank"
                    href="http://onelink.to/35pdxt"
                  >
                    <i className="fab fa-google-play" />
                    <p>
                      {t("home::Get it on")} <br />
                      <span className="big-txt">Google Play</span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-6@md">
              <div className="margin-bottom-lg">
                <p className="font-italic">
                  {t("franchise::Any other questions? Write to us!")} <br />
                  {t("franchise::We at EnerGO are all charged up!")}
                </p>
              </div>
              <ul className="socials__btns flex gap-sm flex-wrap margin-bottom-lg">
                <li>
                  <a target="blank" href="https://t.me/help_energo">
                    <i className="fab fa-telegram-plane icon" />
                    {/* <svg class="icon" viewbox="0 0 32 32"><title>Follow us on Twitter</title><g><path d="M32,6.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6C25.7,3.8,24,3,22.2,3 c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5C10.3,10.8,5.5,8.2,2.2,4.2c-0.6,1-0.9,2.1-0.9,3.3c0,2.3,1.2,4.3,2.9,5.5 c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1 c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1C2.9,27.9,6.4,29,10.1,29c12.1,0,18.7-10,18.7-18.7 c0-0.3,0-0.6,0-0.8C30,8.5,31.1,7.4,32,6.1z"></path></g></svg> */}
                  </a>
                </li>
                <li>
                  <a
                    target="blank"
                    href="https://wa.me/79265358000"
                    className="padding-left-xxxs"
                  >
                    <i className="fab fa-whatsapp icon" />
                    {/* <svg class="icon" viewbox="0 0 32 32"><title>Follow us on Facebook</title><path d="M32,16A16,16,0,1,0,13.5,31.806V20.625H9.438V16H13.5V12.475c0-4.01,2.389-6.225,6.043-6.225a24.644,24.644,0,0,1,3.582.312V10.5H21.107A2.312,2.312,0,0,0,18.5,13v3h4.438l-.71,4.625H18.5V31.806A16,16,0,0,0,32,16Z"></path></svg> */}
                  </a>
                </li>
                <li>
                  <a target="blank" href="mailto:fr@energo.app">
                    <i className="fas fa-envelope icon" />
                    {/* <svg class="icon" viewbox="0 0 32 32"><title>Follow us on Facebook</title><path d="M32,16A16,16,0,1,0,13.5,31.806V20.625H9.438V16H13.5V12.475c0-4.01,2.389-6.225,6.043-6.225a24.644,24.644,0,0,1,3.582.312V10.5H21.107A2.312,2.312,0,0,0,18.5,13v3h4.438l-.71,4.625H18.5V31.806A16,16,0,0,0,32,16Z"></path></svg> */}
                  </a>
                </li>
              </ul>
              <div className="grid flex flex-column gap-md">
                <a href="#0" className="color-black text-md">
                  {t("franchise::Need a charging zone")} <br />{" "}
                  {t("franchise::for an event?")}
                </a>
                <a href="#0" className="color-black text-md">
                  {t("franchise::Do you want to install our")} <br />{" "}
                  {t("franchise::terminal in your establishment?")}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="position-absolute inset-0 bg-primary z-index-1 origin-top-left scroll-fx js-scroll-fx"
          // data-scroll-fx="skewY, -7deg, 0deg, 0%, 50%"
          aria-hidden="true"
        />
      </section>
    </>
  );
}
